import { useEffect, useState } from "react";

const jsonRequest = async (url, method, obj, useCsrf = false) => {
  try {
    const res = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': useCsrf ? document.querySelector('meta[name="csrf-token"]').content : null
      },
      body: JSON.stringify(obj),
    });
    if(res.status === 204) {
      return {success: true };
    } else {
      const json = await res.json();
      if(json.errors) {
        return {success: false, errors: json.errors };
      }
      return {success: true, json };
    }
  } catch (err) {
    console.error(`ERROR: ${err}`);
    return {success: false, err };
  }
}

const post = async (url, obj, useCsrf = false) => {
  return jsonRequest(url, 'POST', obj, useCsrf);
}

const put = async (url, obj) => {
  return jsonRequest(url, 'PUT', obj);
}

const fetchBodoggos = () => {
  const [data, setData] = useState(null);
  const [formattedBodoggos, setFormattedBodoggos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/subapi/v1/working_bodoggos');
        const json = await response.json();
        let bodoggos = {};
        let working = {};
        let notWorking = {};
        json.forEach(bodoggo => {
          if(bodoggo.staked_since){
            working[bodoggo.token_id] = bodoggo;
          } else {
            notWorking[bodoggo.token_id] = bodoggo;
          }
          bodoggos = {
            working,
            notWorking
          };
        });
        setFormattedBodoggos(bodoggos);
        setData(json);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []); // Empty array means this effect runs once on component mount.

  return { data, formattedBodoggos, loading, error };
}

const stakeBodoggos = async (bodoggoIds) => {
  const response = await post(
    '/subapi/v1/working_bodoggos',
    { work_request: { bodoggo_ids: bodoggoIds } }
  );
  return response;
}

const unstakeBodoggos = async (bodoggoIds) => {
  const response = await put(
    '/subapi/v1/working_bodoggos',
    { work_request: { bodoggo_ids: bodoggoIds } }
  );
  return response;
}

const updateSubscriber = async (subscriber) => {
  const response = await put(
    `/subapi/v1/subscribers/${subscriber.id}`,
    { subscriber }
  );
  return response;
}

const claimBones = async () => {
  const response = await post('/subapi/v1/bone_claims');
  return response;
}

const fetchBones = () => {
  const [data, setData] = useState(null);
  const [bones, setBones] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/subapi/v1/bones');
        const json = await response.json();
        setData(json);
        setBones(json.bones);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []); // Empty array means this effect runs once on component mount.

  return { data, bones, loading, error };
}

const createWallet = async (wallet) => {
  const response = await post(
    '/subapi/v1/wallets',
    { wallet }
  );
  return response;
}

const removeWallet = async (walletId) => {
  try {
    const response = await fetch(`/subapi/v1/wallets/${walletId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return {success: true };
  } catch (error) {
    console.error(`ERROR: ${error}`);
    return {success: false, error };
  }
}

const login = async (email, password) => {
  const response = await post(
    '/subapi/v1/sessions',
    { session: {email, password } }
  );
  if(response.json && response.json.errors) {
    return { success: false, errors: response.json.errors };
  }
  return response;
}

const emailLogin = async (email, redirectTo = null) => {
  const response = await post(
    '/form_submissions',
    { form_submission: { email, confirm_redirect: redirectTo } },
    true
  );
  if(response.success) {
    return response.json.form_submission;
  }
  return response;
}

// wallet_address, amount, currency, ref_code, txid
const createMicroTransaction = async (micro_transaction) => {
  const response = await post(
    '/subapi/v1/micro_transactions',
    { micro_transaction }
  );
  return response;
}

const redeemNft = async (redeemable_id, micro_transaction_id) => {
  const response = await post(
    '/subapi/v1/redeem_nft_requests',
    { redeem_request: { redeemable_id, micro_transaction_id } }
  );
  return response;
}

const purchasePack = async (pack_id) => {
  const response = await post(
    '/subapi/v1/pack_purchases',
    { pack_purchase: { pack_id } }
  );
  return response;
}

const revealPack = async (pack_id) => {
  const response = await post(
    '/subapi/v1/reveal_pack_requests',
    { reveal_request: { pack_id } }
  );
  return response;
}

const claimOffer = async (offer_id) => {
  const response = await post(
    '/subapi/v1/offer_claims',
    { offer_claim: { offer_id } }
  );
  return response;
}

const createOffer = async (offer) => {
  const response = await post(
    '/subapi/v1/offers',
    { offer }
  );
  return response;
}

export {
  // Bones
  fetchBones,
  claimBones,
  claimOffer,
  createOffer,
  // Bodoggos Staking
  stakeBodoggos,
  unstakeBodoggos,
  fetchBodoggos,
  // Settings
  createWallet,
  removeWallet,
  updateSubscriber,
  // Login
  login,
  emailLogin,
  // Micro Transactions & Purchases
  createMicroTransaction,
  redeemNft,
  purchasePack,
  revealPack
};